var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"my-2 ml-1"},[_c('b-row',[_c('b-col',{staticClass:"px-0"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"mr-2 flex flexMiddle"},[_c('span',{staticClass:"optionLabel mr-1"},[_vm._v(_vm._s(_vm.options[0].label))]),_vm._v(" "+_vm._s(_vm.options[0].prefix)+" ")]),_c('b-form-input',{class:[
              _vm.optionData(0).errMessages.length > 0 ? 'errItem' : '',
              `template_${_vm.options[0].view_template}`
            ],attrs:{"disabled":!_vm.writable,"maxLength":_vm.options[0].max,"value":_vm.optionData(0).field_answer_text,"placeholder":_vm.options[0].placeholder},on:{"input":function($event){return _vm.kickSetText($event, _vm.options[0].id)}}}),_c('b-input-group-append',{staticClass:"ml-2 flex flexMiddle"},[_vm._v(" "+_vm._s(_vm.options[0].suffix)+" ")])],1)],1)],1),_c('b-row',{staticClass:"errorWrap"},_vm._l((_vm.optionData(0).errMessages),function(msg,index){return _c('p',{key:index,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),0)],1),_c('div',{staticClass:"my-2 ml-1"},[_c('b-row',[_c('b-col',{staticClass:"px-0"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"mr-2 flex flexMiddle"},[_c('span',{staticClass:"optionLabel mr-1"},[_vm._v(_vm._s(_vm.options[1].label))]),_vm._v(" "+_vm._s(_vm.options[1].prefix)+" ")]),_c('b-form-input',{class:[
              _vm.optionData(1).errMessages.length > 0 ? 'errItem' : '',
              `template_${_vm.options[1].view_template}`
            ],attrs:{"disabled":!_vm.writable,"maxLength":_vm.options[1].max,"value":_vm.optionData(1).field_answer_text,"placeholder":_vm.options[1].placeholder},on:{"input":function($event){return _vm.kickSetText($event, _vm.options[1].id)}}}),_c('b-input-group-append',{staticClass:"ml-2 flex flexMiddle"},[_vm._v(" "+_vm._s(_vm.options[1].suffix)+" ")])],1)],1)],1),_c('b-row',{staticClass:"errorWrap"},_vm._l((_vm.optionData(1).errMessages),function(msg,index){return _c('p',{key:index,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),0)],1),_c('div',{staticClass:"my-2 ml-1"},[_c('b-row',[_c('b-col',{staticClass:"px-0"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"mr-2 flex flexMiddle"},[_c('span',{staticClass:"optionLabel mr-1"},[_vm._v(_vm._s(_vm.options[2].label))]),_vm._v(" "+_vm._s(_vm.options[2].prefix)+" ")]),_c('b-form-input',{class:[
              _vm.optionData(2).errMessages.length > 0 ? 'errItem' : '',
              `template_${_vm.options[2].view_template}`
            ],attrs:{"disabled":!_vm.writable,"type":"number","max":"5","min":"2","maxLength":_vm.options[2].max,"value":_vm.optionData(2).field_answer_text,"placeholder":_vm.options[2].placeholder},on:{"input":function($event){return _vm.kickSetText($event, _vm.options[2].id)}}}),_c('b-input-group-append',{staticClass:"ml-2 flex flexMiddle"},[_vm._v(" "+_vm._s(_vm.options[2].suffix)+" ")])],1)],1)],1),_c('b-row',{staticClass:"errorWrap"},_vm._l((_vm.optionData(2).errMessages),function(msg,index){return _c('p',{key:index,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),0)],1),_c('div',{staticClass:"my-2 ml-1"},[_c('b-row',[_c('b-col',{staticClass:"px-0"},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"mr-2 flex flexMiddle"},[_c('span',{staticClass:"optionLabel mr-1"},[_vm._v(_vm._s(_vm.options[3].label))]),_vm._v(" "+_vm._s(_vm.options[3].prefix)+" ")]),_c('b-form-input',{class:[
              `template_${_vm.options[3].view_template}`
            ],attrs:{"readonly":"","disabled":!_vm.writable,"maxLength":_vm.options[3].max,"value":_vm.optionData(3).field_answer_text,"placeholder":_vm.options[3].placeholder}}),_c('b-input-group-append',{staticClass:"ml-2 flex flexMiddle"},[_vm._v(" "+_vm._s(_vm.options[3].suffix)+" ")])],1)],1)],1)],1),(_vm.notice)?_c('b-row',{staticClass:"ml-1"},[_c('b-col',{staticClass:"px-0"},[_c('p',{staticClass:"mb-0 f-13"},[_vm._v(_vm._s(_vm.notice))])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
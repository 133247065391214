<template>
  <div>
    <b-table-simple
      class="my-2"
      :borderless="false">
      <b-thead>
        <b-tr>
          <b-th class="pb-0 pr-0 no-border">
            <span class="fw-3 ml-2"></span>
          </b-th>
          <b-th class="pb-0 pr-0 no-border">
            <span class="fw-3 ml-2">金額（万円）</span>
          </b-th>
          <b-th class="pb-0 pr-0 no-border">
            <span class="fw-3 ml-2">調達先</span>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td
            class="text-middle no_border">
            <span class="fw-6">①既に調達が確定している額</span>
          </b-td>
          <b-td class="no_border">
            <b-form-input
              :disabled="!writable"
              @input="updateValue(1, 1, $event)"
              :value="optionData(1, 1).field_answer_text"
              :class="[optionData(1, 1).errMessages.length > 0 ? 'errItem' : '', 'moneyInput']"
              :maxLength="options[0].max"
              placeholder="入力してください"/>
            <b-row class="ml-1"
              v-for="(msg, index) in optionData(1, 1).errMessages" :key="index">
              <p class="error my-1">
                {{msg}}
              </p>
            </b-row>
          </b-td>
          <b-td class="no_border">
            <b-form-input
              :disabled="!writable"
              @input="updateValue(2, 1, $event)"
              :value="optionData(2, 1).field_answer_text"
              :class="[optionData(2, 1).errMessages.length > 0 ? 'errItem' : '', 'Suppliers']"
              :maxLength="options[1].max"
              placeholder="入力してください"/>
            <b-row class="ml-1"
              v-for="(msg, index) in optionData(2, 1).errMessages" :key="index">
              <p class="error my-1">
                {{msg}}
              </p>
            </b-row>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td rowspan="2"
            class="yotei">
            <span class="fw-6">②調達を予定している額</span>
          </b-td>
          <b-td>
            <b-form-input
              :disabled="!writable"
              @input="updateValue(1, 2, $event)"
              :value="optionData(1, 2).field_answer_text"
              :class="[optionData(1, 2).errMessages.length > 0 ? 'errItem' : '', 'moneyInput']"
              :maxLength="options[0].max"
              placeholder="入力してください"/>
            <b-row class="ml-1"
              v-for="(msg, index) in optionData(1, 2).errMessages" :key="index">
              <p class="error my-1">
                {{msg}}
              </p>
            </b-row>
          </b-td>
          <b-td>
            <b-form-input
              :disabled="!writable"
              @input="updateValue(2, 2, $event)"
              :value="optionData(2, 2).field_answer_text"
              :class="[optionData(2, 2).errMessages.length > 0 ? 'errItem' : '', 'Suppliers']"
              :maxLength="options[1].max"
              placeholder="入力してください"/>
            <b-row class="ml-1"
              v-for="(msg, index) in optionData(2, 2).errMessages" :key="index">
              <p class="error my-1">
                {{msg}}
              </p>
            </b-row>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="no_border">
            <b-form-input
              :value="applictionMoney"
              readonly
              class="moneyInput"
              placeholder=""/>
          </b-td>
          <b-td class="text-middle no_border">
            住友電工グループ社会貢献基金からの助成
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="">
            <span class="blockLabel fw-6">③当該研究費総額</span>
          </b-td>
          <b-td>
            <span class="sum">合計：</span>
            <b-form-input
              :value="allMoney"
              readonly
              :class="[
                !matchSum ? 'errItem' : '',
                'moneyInput'
              ]"
              placeholder=""/>
              <b-row class="ml-1">
                <p
                  v-if="!matchSum"
                  class="error my-1">
                  合計金額が一致していません。
                </p>
              </b-row>
          </b-td>
          <b-td class="text-middle text-left">
            （ ※①＋②＝③となります ）
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <p class="mb-0 f-13">
      *「調達先」には校費、科学研究費補助金、他財団からの助成金等を記入してください。助成対象期間中に科学研究費補助金を申請中の場合には必ず記入してください。
    </p>
    <p class="mb-0 f-13">*「既に調達が確定している額」及び「調達を予定している額」がない場合は、金額に「0」、調達先に「なし」と入力してください。</p>
    <p class="mb-0 f-13">* 調達先が複数ある場合は、合計額を入力してください。</p>
    <p class="mb-0 f-13">* 数字は半角で入力してください。</p>
  </div>
</template>
<script>

export default {
  name: 'PartsApplicationTable',
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            input_type: null,
            prefix: '',
            suffix: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: '',
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    params: { type: [Object, Array], default: () => [] },
  },
  data() {
    return {
    };
  },
  methods: {
    updateValue(column, line, value) {
      const option = this.options[column - 1];

      const param = {
        field_id: this.fieldId,
        option_id: option.id,
        answer_no: line,
        value,
      };
      this.$store.commit('gasApplicationInput/setText', param);
    },
  },
  computed: {
    optionData() {
      const self = this;
      return (column, line) => {
        const option = this.options[column - 1];
        const { inputs } = this.$store.state.gasApplicationInput;
        const optionData = inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === option.id;
          const sameAnswerNo = input.answer_no === line;
          return sameField && sameOption && sameAnswerNo;
        });
        if (typeof optionData === 'undefined') {
          return [];
        }
        return optionData;
      };
    },
    allMoney() {
      const { inputs } = this.$store.state.gasApplicationInput;
      const optionData = inputs.find((input) => {
        const sameField = input.field_id === this.params[0].param;
        const sameOption = input.option_id === this.params[1].param;
        const sameAnswerNo = input.answer_no === this.params[2].param;
        return sameField && sameOption && sameAnswerNo;
      });
      return optionData.field_answer_text;
    },
    applictionMoney() {
      const { inputs } = this.$store.state.gasApplicationInput;
      const optionData = inputs.find((input) => {
        const sameField = input.field_id === this.params[3].param;
        const sameOption = input.option_id === this.params[4].param;
        const sameAnswerNo = input.answer_no === this.params[5].param;
        return sameField && sameOption && sameAnswerNo;
      });
      return optionData.field_answer_text;
    },
    matchSum() {
      const money1 = Number(this.optionData(1, 1).field_answer_text);
      const money2 = Number(this.optionData(1, 2).field_answer_text);
      const result = Number(this.allMoney) === (Number(this.applictionMoney) + money1 + money2);
      return result;
    },
  },
  created() {
  },
};
</script>

<style scoped>
.errItem {
  border:solid 1px #dc3545 !important;
}
.error {
  width: 105px;
  font-size: 80%;
  color: #dc3545;
  /* bootstrapで太字が当てられていたので。 */
  font-weight: normal;
}

  input, select {
    height: 50px;
  }

  .moneyInput {
    width: 105px;
  }

  .Suppliers {
    width: 100%;
  }

  .plus {
    margin-bottom: 4px;
  }
  .closeTh {
    padding-top:20px;
  }

  .btn-outline-primary:hover {
    background-color: #fff;
    opacity: 0.6;
  }

  .text-middle {
    vertical-align: middle;
  }

  .no-border {
    border: 0;
  }

  .money {
    width: 100px;
  }

  .sum {
    position: absolute;
    margin-left: -50px;
    margin-top: 15px;
  }

  .yotei {
    padding-top: 21px;
  }

  .no_border {
    border-width: 0;
  }

  .blockLabel {
    margin-top: 15px;
    display: block;
  }
</style>

<template>
  <div class="col-sm-12">
    <div
      class="my-2 ml-1"
      v-for="(choice, choiceId) in checkboxData.choices" :key="choiceId">
      <!-- 上位の選択肢 -->
      <b-form-checkbox
        class="gas-form-checkbox-parts"
        :disabled="!writable"
        :name="`mainCheck${choiceId}`"
        :value="choiceId"
        v-model="optionData(checkboxData.mainOptionId).checkbox_answers"
        @change="resetChildValue($event, checkboxData.mainOptionId, choiceId)"
        stacked>
        <div class="choice-text inlineBlock">{{ choice.text }}</div>
        <!-- 中位テキスト追加時 -->
        <div
          v-if="choice.subOptionId && choice.subInputType === 1"
          class="choice-text sub-text inlineBlock">
          <b-form-input
            :class="[
              optionData(choice.subOptionId).errMessages.length > 0 ? 'errItem' : '',
            ]"
            :disabled="!writable || !checkThisChoice(checkboxData.mainOptionId, choiceId)"
            v-model="optionData(choice.subOptionId).field_answer_text"/>
        </div>
      </b-form-checkbox>
      <!-- 中位の選択肢 -->
      <div
        v-if="choice.subOptionId && choice.subInputType === 7"
        class="ml-3">
        <div
          class="my-2 ml-1 mr-2 inlineBlock"
          v-for="(subChoice, subChoiceId) in choice.subChoices" :key="subChoiceId">
          <b-form-checkbox
            class="gas-form-checkbox-parts"
            :disabled="!writable || !checkThisChoice(checkboxData.mainOptionId, choiceId)"
            :name="`subCheck${subChoiceId}`"
            :value="subChoiceId"
            v-model="optionData(choice.subOptionId).checkbox_answers"
            @change="resetChildValue($event, choice.subOptionId, subChoiceId)">
            <div class="choice-text inlineBlock">{{ subChoice.text }}</div>
            <!-- 下位テキスト追加時 -->
            <div
              v-if="subChoice.subSubOptionId && subChoice.subSubInputType === 1"
              class="choice-text sub-sub-text inlineBlock">
              <b-form-input
                :class="[
                  optionData(subChoice.subSubOptionId).errMessages.length > 0 ? 'errItem' : '',
                ]"
                :disabled="!writable
                  || !checkThisChoice(checkboxData.mainOptionId, choiceId)
                  || !checkThisChoice(choice.subOptionId, subChoiceId)"
                v-model="optionData(subChoice.subSubOptionId).field_answer_text"/>
            </div>
          </b-form-checkbox>
        </div>
      </div>
      <div
        v-if="choice.subOptionId"
        class="">
        <p class="error my-1"
          v-for="(msg, index) in optionData(choice.subOptionId).errMessages"
          :key="index">
          {{msg}}
        </p>
        <div
          class=""
          v-for="(subChoice, subChoiceId) in choice.subChoices" :key="subChoiceId">
          <div
            v-if="optionData(subChoice.subSubOptionId)">
            <p class="error my-1"
              v-for="(msg, index) in optionData(subChoice.subSubOptionId).errMessages"
              :key="index">
              {{msg}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'PartsApplicationCheck',
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    writable: { type: Boolean, default: false },
    view_template: { type: Number, default: 1 },
    rules: { type: [Object, Array], fedault: () => {} },
  },
  data() {
    return {
      test: [],
    };
  },
  methods: {
    setSubChoice(option, rawCheckBoxData) {
      const checkBoxData = cloneDeep(rawCheckBoxData);
      const subChoices = {};
      checkBoxData.choices[option.parent_choice_id].subOptionId = option.id;
      // チェックボックスの時
      if (option.input_type === 7) {
        checkBoxData.choices[option.parent_choice_id].subInputType = 7;
        Object.keys(option.choice_texts).forEach((choiceId) => {
          subChoices[choiceId] = {};
          subChoices[choiceId].text = option.choice_texts[choiceId];
          if (!checkBoxData.choices[option.parent_choice_id].choiceIdList) {
            checkBoxData.choices[option.parent_choice_id].choiceIdList = [];
          }
          checkBoxData.choices[option.parent_choice_id].choiceIdList.push(choiceId);
        });
        checkBoxData.choices[option.parent_choice_id].subChoices = subChoices;
      }
      // テキストのとき
      if (option.input_type === 1) {
        checkBoxData.choices[option.parent_choice_id].subInputType = 1;
      }
      return checkBoxData;
    },
    resetChildValue(event, optionId, choiceId) {
      // チェックを外したときのみ動作
      if (event !== null) {
        return;
      }
      // 今、チェックを外したものを親とする子オプションを検索する
      const formOption = this.options.find((option) => {
        const sameOption = option.parent_option_id === optionId;
        const sameChoice = option.parent_choice_id === Number(choiceId);
        return sameOption && sameChoice;
      });
      if (!formOption) {
        return;
      }
      const param = {
        field_id: this.fieldId,
        option_id: formOption.id,
        answer_no: 1,
      };
      this.$store.commit('gasApplicationInput/resetValue', param);
      // 子オプションの中に子オプションを親とする孫オプションもすべて初期化する処理をする。
      const subFormOption = this.options.find((option) => {
        const sameOption = option.parent_option_id === formOption.id;
        return sameOption;
      });
      if (!subFormOption) {
        return;
      }
      const subParam = {
        field_id: this.fieldId,
        option_id: subFormOption.id,
        answer_no: 1,
      };
      this.$store.commit('gasApplicationInput/resetValue', subParam);
    },
  },
  computed: {
    optionData() {
      const self = this;
      return (optionId) => {
        const { inputs } = this.$store.state.gasApplicationInput;
        const optionData = inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === Number(optionId);
          const sameAnswerNo = input.answer_no === 1;
          return sameField && sameOption && sameAnswerNo;
        });
        return optionData;
      };
    },
    checkboxData() {
      let checkBoxData = {};
      if (this.options.length === 0) {
        return checkBoxData;
      }
      const rawOptionData = this.options.slice();
      // メインになるやつ
      let mainId = null;
      rawOptionData.forEach((option, index) => {
        if (!option.parent_option_id) {
          mainId = option.id;
          checkBoxData = {
            mainOptionId: mainId,
          };
          const choices = {};
          Object.keys(option.choice_texts).forEach((choiceId) => {
            choices[choiceId] = {};
            choices[choiceId].text = option.choice_texts[choiceId];
          });
          checkBoxData.choices = choices;
          rawOptionData.splice(index, 1);
        }
      });
      // サブになるやつ
      rawOptionData.forEach((option, index) => {
        if (option.parent_option_id === checkBoxData.mainOptionId) {
          checkBoxData = this.setSubChoice(option, checkBoxData);
          rawOptionData.splice(index, 1);
        }
      });
      // サブのサブになるやつ(サブのチェックボックスの「その他」選択時テキスト)
      rawOptionData.forEach((option, index) => {
        Object.keys(checkBoxData.choices).forEach((choiceId) => {
          const { choiceIdList } = checkBoxData.choices[choiceId];
          if (choiceIdList && choiceIdList.indexOf(option.parent_choice_id)) {
            const { subChoices } = checkBoxData.choices[choiceId];
            subChoices[option.parent_choice_id].subSubOptionId = option.id;
            subChoices[option.parent_choice_id].subSubInputType = 1;
            rawOptionData.splice(index, 1);
          }
        });
      });
      return checkBoxData;
    },
    checkThisChoice() {
      return (optionId, choiceId) => {
        if (!this.optionData(optionId)) {
          return false;
        }
        const checkValueList = this.optionData(optionId).checkbox_answers;
        return checkValueList.indexOf(choiceId) !== -1;
      };
    },
  },
};
</script>

<style>

.gas-form-checkbox-parts .custom-control-label::before {
  width: 25px;
  height: 25px;
}

.gas-form-checkbox-parts .custom-control-label::after {
  width: 25px;
  height: 25px;
}

</style>

<style scoped>
.errItem {
  border:solid 1px #dc3545 !important;
}
.error {
  font-size: 80%;
  color: #dc3545;
}

.choice-text {
  margin-left: 10px;
  height: 30px;
  margin-top: 4px;
}

.template_1 {
  width: 220px;
  max-width: 220px;
}

.template_2 {
  width: 400px;
  max-width: 400px;
}

.optionLabel.label_1 {
  width: 55px;
}

.optionLabel.label_2 {
  width: 160px;
}

.error_1 {
  margin-left: 60px;
}

.error_2 {
  margin-left: 165px;
}

.sub-text {
  margin-top: -10px;
  width: 600px;
}

.sub-sub-text {
  margin-top: -10px;
  width: 200px;
}
</style>

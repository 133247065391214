<template>
  <div>
    <div
      class="my-2 ml-1">
      <b-row>
        <b-col class="px-0">
          <b-input-group>
            <b-input-group-prepend
              class="mr-2 flex flexMiddle">
              <span class="optionLabel mr-1">{{options[0].label}}</span>
              {{options[0].prefix}}
            </b-input-group-prepend>
            <b-form-input
              :disabled="!writable"
              :class="[
                optionData(0).errMessages.length > 0 ? 'errItem' : '',
                `template_${options[0].view_template}`
              ]"
              :maxLength="options[0].max"
              :value="optionData(0).field_answer_text"
              @input="kickSetText($event, options[0].id)"
              :placeholder="options[0].placeholder"/>
            <b-input-group-append
              class="ml-2 flex flexMiddle">
              {{options[0].suffix}}
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="errorWrap">
        <p class="error my-1"
          v-for="(msg, index) in optionData(0).errMessages" :key="index">
          {{msg}}
        </p>
      </b-row>
    </div>

    <div
      class="my-2 ml-1">
      <b-row>
        <b-col class="px-0">
          <b-input-group>
            <b-input-group-prepend
              class="mr-2 flex flexMiddle">
              <span class="optionLabel mr-1">{{options[1].label}}</span>
              {{options[1].prefix}}
            </b-input-group-prepend>
            <b-form-input
              :disabled="!writable"
              :class="[
                optionData(1).errMessages.length > 0 ? 'errItem' : '',
                `template_${options[1].view_template}`
              ]"
              :maxLength="options[1].max"
              :value="optionData(1).field_answer_text"
              @input="kickSetText($event, options[1].id)"
              :placeholder="options[1].placeholder"/>
            <b-input-group-append
              class="ml-2 flex flexMiddle">
              {{options[1].suffix}}
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="errorWrap">
        <p class="error my-1"
          v-for="(msg, index) in optionData(1).errMessages" :key="index">
          {{msg}}
        </p>
      </b-row>
    </div>

    <div
      class="my-2 ml-1">
      <b-row>
        <b-col class="px-0">
          <b-input-group>
            <b-input-group-prepend
              class="mr-2 flex flexMiddle">
              <span class="optionLabel mr-1">{{options[2].label}}</span>
              {{options[2].prefix}}
            </b-input-group-prepend>
            <b-form-input
              :disabled="!writable"
              :class="[
                optionData(2).errMessages.length > 0 ? 'errItem' : '',
                `template_${options[2].view_template}`
              ]"
              type="number"
              max="5"
              min="2"
              :maxLength="options[2].max"
              :value="optionData(2).field_answer_text"
              @input="kickSetText($event, options[2].id)"
              :placeholder="options[2].placeholder"/>
            <b-input-group-append
              class="ml-2 flex flexMiddle">
              {{options[2].suffix}}
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="errorWrap">
        <p class="error my-1"
          v-for="(msg, index) in optionData(2).errMessages" :key="index">
          {{msg}}
        </p>
      </b-row>
    </div>

    <div
      class="my-2 ml-1">
      <b-row>
        <b-col class="px-0">
          <b-input-group>
            <b-input-group-prepend
              class="mr-2 flex flexMiddle">
              <span class="optionLabel mr-1">{{options[3].label}}</span>
              {{options[3].prefix}}
            </b-input-group-prepend>
            <b-form-input
              readonly
              :disabled="!writable"
              :class="[
                `template_${options[3].view_template}`
              ]"
              :maxLength="options[3].max"
              :value="optionData(3).field_answer_text"
              :placeholder="options[3].placeholder"/>
            <b-input-group-append
              class="ml-2 flex flexMiddle">
              {{options[3].suffix}}
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>


    <b-row
      v-if="notice"
      class="ml-1">
      <b-col class="px-0">
        <p class="mb-0 f-13">{{notice}}</p>
      </b-col>
    </b-row>
  </div>
</template>
<script>

export default {
  name: 'PartsApplicationInput',
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    view_template: { type: Number, default: 1 },
  },
  data() {
    return {
    };
  },
  methods: {
    kickSetText(value, optionId) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: 1, // テキストは当面１つ固定
        value,
      };
      this.$emit('kickSetText', param);
      const calcParam = {
        field_id: this.fieldId,
        option_id: this.options[3].id,
        answer_no: 1,
        value: this.calc(),
      };
      this.$emit('kickSetText', calcParam);
    },
    // 4番めの項目の計算値の取得。ただし一方が数字でない場合や空の場合は空文字を返す
    calc() {
      const value1 = this.optionData(1).field_answer_text;
      const value2 = this.optionData(2).field_answer_text;
      const pattern = /^([1-9]\d*|0)(\.\d+)?$/;// 符号なし少数OK
      if (!pattern.test(value1) || !pattern.test(value2)) {
        return '';
      }
      return Math.round(Number(value1) / Number(value2));
    },
  },
  computed: {
    optionData() {
      const self = this;
      return (index) => {
        const option = this.options[index];
        const { inputs } = this.$store.state.gasApplicationInput;
        const optionData = inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === option.id;
          const sameAnswerNo = input.answer_no === 1;
          return sameField && sameOption && sameAnswerNo;
        });
        if (typeof optionData === 'undefined') {
          return [];
        }
        return optionData;
      };
    },
    singleYearMoney() {
      const value1 = this.optionData(0).field_answer_text;
      const value2 = this.optionData(1).field_answer_text;
      const pattern = /^([1-9]\d*|0)(\.\d+)?$/;// 符号なし少数OK
      if (!pattern.test(value1) || !pattern.test(value2)) {
        return '';
      }
      return Math.round(Number(value1) / Number(value2));
    },
  },
};
</script>

<style scoped>
.errItem {
  border:solid 1px #dc3545 !important;
}
.error {
  font-size: 80%;
  color: #dc3545;
}
input {
  height: 50px;
}
.template_1 {
  width: 220px;
  max-width: 220px;
}

.template_2 {
  width: 400px;
  max-width: 400px;
}

.optionLabel {
  width: 105px;
}

.errorWrap {
  margin-left: 110px;
}
</style>

<template>
  <div>
    <b-table-simple
      class="mt-2 mb-0"
      :borderless="true">
      <b-thead>
        <b-tr>
          <b-th
            v-for="(option, index) in options" :key="index"
            class="pb-0 pr-0">
            <span class="fw-3 ml-2">{{option.label}}</span>
          </b-th>
          <b-th class="pb-0 pr-0 deleteTh">
            <span class="fw-3">削除</span>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(line) of maxAnswerNums[fieldId] " :key="line">
          <b-td v-for="(option, index) in options" :key="index">
            <b-form-input
              :disabled="!writable"
              :class="optionData(option.id, line).errMessages.length > 0 ? 'errItem' : ''"
              :maxLength="option.max"
              :value="optionData(option.id, line).field_answer_text"
              @input="kickSetText($event, option.id, line)"
              v-if="option.input_type===tableInputTypes.TEXT ||
                option.input_type===tableInputTypes.NUMBER"
              :placeholder="option.placeholder"/>
            <b-form-select
              :disabled="!writable"
              :class="optionData(option.id, line).errMessages.length > 0 ? 'errItem' : ''"
              :value="optionData(option.id, line).field_answer_id"
              v-if="option.input_type===tableInputTypes.SELECT"
              @input="kickSetSelect($event, option.id, line)"
              :options="option.choice_texts">
              >
              <template v-slot:first>
                <option :value="null" selected>-- 選択してください。 --</option>
              </template>
            </b-form-select>
            <span
              v-if="option.input_type===tableInputTypes.FIXED">
              {{options[n].choice_texts}}
            </span>

            <b-row class="ml-1"
              v-for="(msg, index) in optionData(option.id, line).errMessages" :key="index">
              <p class="error my-1">
                {{msg}}
              </p>
            </b-row>
          </b-td>
          <b-td class="closeTh flex flexMiddle filexCenter">
            <img
              src="@/assets/svg/close.svg"
              v-if="writable"
              @click="removeTr(line)"
              class="tdDeleteBtn">
          </b-td>
        </b-tr>

        <!-- sumColmumの１番目のパラメータは何の合計を見るかの対象のoption_id -->
        <b-tr
          v-if="sum_column">
          <b-td v-for="(option, index) in options" :key="index">
            <div
              v-if="option.id == rules.sum_column.option_id">
              <span
                class="sum">合計：</span>
              <b-form-input
                :class="[
                  !sumMatch ? 'errItem' : '',
                ]"
                readonly
                :value="sum"/>
              <b-row
                v-show="!sumMatch"
                class="ml-1">
                <p class="error my-1">
                  {{sumMsg}}
                </p>
              </b-row>
            </div>
          </b-td>
        </b-tr>

      </b-tbody>
    </b-table-simple>
    <div class="flex flexRight"
      v-if="writable">
      <b-button
      class="addBtn"
      :disabled="!writable || !(this.maxAnswerNums[this.fieldId] < this.max_input_number)"
      @click="addTr"
      variant="outline-primary">
        <img class="plus" src="@/assets/svg/plus.svg">
        入力欄を追加
      </b-button>
    </div>
  </div>
</template>
<script>
import CONST_OPTIONS from '@/constants/options';

export default {
  name: 'PartsApplicationTable',
  props: {
    fieldId: { type: Number },
    is_variable: { type: [Boolean, Number], default: true },
    input_number: { type: Number, default: 1 },
    min_input_number: { type: Number, default: 1 },
    max_input_number: { type: Number, default: 1 },
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            input_type: null,
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
          },
        ];
      },
    },
    notice: {},
    writable: { type: Boolean, default: false },
    rules: { type: [Object, Array], fedault: () => {} },
  },
  data() {
    return {
    };
  },
  methods: {
    addTr() {
      if (this.maxAnswerNums[this.fieldId] < this.max_input_number) {
        // データ上で入力項目の数を追加し、入力欄を追加する
        const newLineNum = this.maxAnswerNums[this.fieldId] + 1;
        const addParam = this._getInputParameter(newLineNum);
        const ansNumParam = {
          fieldId: this.fieldId,
          newLineNum,
        };
        this.$store.commit('gasApplicationInput/setMaxAnswerNums', ansNumParam);
        this.$store.commit('gasApplicationInput/addInputs', addParam);
      }
    },
    removeTr(line) {
      // worning conputedにしたら検知しないことがある。たぶんthis.fieldIdのりアクティブを殺してるところがある？
      if (this.maxAnswerNums[this.fieldId] > this.min_input_number) {
        // 末尾の入力欄を削除し、入力項目の数も１つ減らす
        const removeParam = {
          fieldId: this.fieldId,
          line,
        };
        const newLineNum = this.maxAnswerNums[this.fieldId] - 1;
        const ansNumParam = {
          fieldId: this.fieldId,
          newLineNum,
        };
        this.$store.commit('gasApplicationInput/removeInputs', removeParam);
        this.$store.commit('gasApplicationInput/setMaxAnswerNums', ansNumParam);
      }
    },
    _getInputParameter(newLineNum) {
      const params = [];
      const commonParam = {
        field_id: this.fieldId,
        answer_no: newLineNum,
      };
      this.options.forEach((option) => {
        const param = Object.assign({}, commonParam);
        param.option_id = option.id;
        params.push(param);
      });
      return params;
    },
    kickSetText(value, optionId, lineNum) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: lineNum,
        value,
      };
      this.$emit('kickSetText', param);
    },
    kickSetSelect(value, optionId, lineNum) {
      let setValue = value;
      if (setValue) {
        setValue = Number(setValue);
      }
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: lineNum,
        value: setValue,
      };
      this.$emit('kickSetSelect', param);
    },
  },
  computed: {
    maxAnswerNums() {
      return this.$store.state.gasApplicationInput.maxAnswerNums;
    },
    tableInputTypes() {
      return CONST_OPTIONS.TABLE_INPUT_TYEPS;
    },
    optionData() {
      const self = this;
      return (optionId, line) => {
        const { inputs } = this.$store.state.gasApplicationInput;
        const optionData = inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === optionId;
          const sameAnswerNo = input.answer_no === line;
          return sameField && sameOption && sameAnswerNo;
        });
        if (typeof optionData === 'undefined') {
          return [];
        }
        return optionData;
      };
    },
    sum_column() {
      return this.rules.sum_column;
    },
    sum() {
      let sum = '';
      if (this.rules.sum_column) {
        const optionId = this.rules.sum_column.option_id;
        const { inputs } = this.$store.state.gasApplicationInput;
        sum = 0;
        inputs.forEach((input) => {
          const sameOption = input.option_id === optionId;
          if (sameOption) {
            sum += Number(input.field_answer_text);
          }
        });
      }
      return sum;
    },
    sumMatch() {
      let result = true;
      let targetValue = '';
      if (this.rules.sum_column) {
        const optionId = Number(this.sum_column.params[1].parameter);
        const { inputs } = this.$store.state.gasApplicationInput;
        const targetOption = inputs.find((input) => {
          const sameOption = input.option_id === optionId;
          const sameAnswer = input.answer_no === 1;
          return sameOption && sameAnswer;
        });
        targetValue = Number(targetOption.field_answer_text);
        result = targetValue === this.sum;
      }
      return result;
    },
    sumMsg() {
      let msg = '';
      if (this.rules.sum_column) {
        const data = this.$store.state.gasApplicationForm.optionDataList;
        const { fieldLabel, optionLabel } = data[this.sum_column.params[1].parameter];
        if (fieldLabel === optionLabel) {
          msg = `${optionLabel}と一致していません。`;
        } else {
          msg = `${fieldLabel}の${optionLabel}と一致していません。`;
        }
      }
      return msg;
    },
  },
};
</script>

<style scoped>
  .errItem {
    border:solid 1px #dc3545 !important;
  }
  .error {
    font-size: 80%;
    color: #dc3545;
    /* bootstrapで太字が当てられていたので。 */
    font-weight: normal;
  }

  input, select {
    height: 50px;
  }

  .addBtn {
    color:#0A8EA7 !important;
    border-color: #0A8EA7 !important;
    border-radius: 30px;
    width: 200px;
  }

  .plus {
    margin-bottom: 4px;
  }
  .closeTh {
    padding-top:20px;
  }

  .btn-outline-primary:hover {
    background-color: #fff;
    opacity: 0.6;
  }

  .sum {
    position: absolute;
    margin-left: -50px;
    margin-top: 15px;
  }

  .deleteTh {
    min-width: 50px;
  }
</style>

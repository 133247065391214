var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"my-2 ml-1"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-input-group',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.uploadedFileName && !_vm.optionData(option.id).dbFileName),expression:"!uploadedFileName && !optionData(option.id).dbFileName"}],staticClass:"mb-2 fileName"},[_vm._v("ファイル名：ファイルが選択されていません")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploadedFileName),expression:"uploadedFileName"}],staticClass:"mb-2 fileName"},[_vm._v(" ファイル名："+_vm._s(_vm.uploadedFileName)+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.uploadedFileName && _vm.optionData(option.id).dbFileName),expression:"!uploadedFileName && optionData(option.id).dbFileName"}],staticClass:"mb-2 fileName",on:{"click":function($event){_vm.downloadFormFile(_vm.fieldId, option.id, _vm.optionData(option.id).dbFileName)}}},[_vm._v(" ファイル名： "),_c('b-link',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.optionData(option.id).dbFileName))])],1)]),(_vm.writable)?_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"mr-1 flex flexMiddle"},[_vm._v(" "+_vm._s(option.label)+" "+_vm._s(option.prefix)+" ")]),_c('div',{class:[
              _vm.isDragging ? 'is-dragging' : '',
              _vm.optionData(option.id).errMessages.length > 0 ? 'errItem' : '',
              'drop', 'flex', 'flexMiddle', 'flexCenter'
            ],on:{"dragover":function($event){$event.preventDefault();return _vm.toggleDrag($event, true)},"dragleave":function($event){$event.preventDefault();return _vm.toggleDrag($event, false)},"drop":function($event){$event.preventDefault();return _vm.onDrop($event, option.id)}}},[_c('span',[_c('span',{class:[_vm.isDragging ? 'is-dragging' : '',
                  'dragText',
                  'fw-6']},[_vm._v("ここにファイルをドラッグ＆ドロップ または")]),_c('label',{staticClass:"fileText fw-6 selectFile",attrs:{"for":`file_${_vm.fieldId}`}},[_vm._v("［ ファイルを選択 ］")]),_c('p',{class:[_vm.isDragging ? 'is-dragging' : '', 'fileNotation', 'mb-0']},[_vm._v(" （ファイルを再アップロードした場合アップロード済みのファイルは上書きされます） ")]),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","id":`file_${_vm.fieldId}`},on:{"change":function($event){return _vm.upload($event, option.id)}}})])]),_c('b-input-group-append',{staticClass:"ml-1 flex flexMiddle"},[_vm._v(" "+_vm._s(option.suffix)+" ")])],1):_vm._e()],1)],1),_c('b-row',{staticClass:"ml-1"},_vm._l((_vm.optionData(option.id).errMessages),function(msg,index){return _c('p',{key:index,staticClass:"error my-1"},[_vm._v(" "+_vm._s(msg)+" ")])}),0)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div
      v-for="(option, index) in options" :key="index"
      class="my-2 ml-1">
      <b-row>
        <b-col class="px-0">
          <b-input-group>
            <b-input-group-prepend
              class="mr-1 flex flexMiddle">
              {{option.label}}
              {{option.prefix}}
            </b-input-group-prepend>
            <b-form-textarea
              :disabled="!writable"
              :class="optionData(option.id).errMessages.length > 0 ? 'errItem' : ''"
              :maxLength="option.max"
              :rows="rows[option.view_template]"
              :value="optionData(option.id).field_answer_text"
              @input="kickSetText($event, option.id)"
              :placeholder="option.placeholder"/>
            <b-input-group-append
              class="ml-1 flex flexMiddle">
              {{option.suffix}}
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="ml-1">
        <p class="error my-1"
          v-for="(msg, index) in optionData(option.id).errMessages" :key="index">
          {{msg}}
        </p>
      </b-row>
    </div>

  </div>
</template>
<script>

export default {
  name: 'PartsApplicationTextarea',
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
  },
  data() {
    return {
      // viewTemplateに応じたrows テンプレートNo：行数
      rows: {
        1: 2,
        2: 4,
        3: 6,
      },
    };
  },
  methods: {
    kickSetText(value, optionId) {
      const param = {
        field_id: this.fieldId,
        option_id: optionId,
        answer_no: 1, // テキストは当面１つ固定
        value,
      };
      this.$emit('kickSetText', param);
    },
  },
  computed: {
    optionData() {
      const self = this;
      return (optionId) => {
        const { inputs } = this.$store.state.gasApplicationInput;
        const optionData = inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === optionId;
          const sameAnswerNo = input.answer_no === 1;
          return sameField && sameOption && sameAnswerNo;
        });
        return optionData;
      };
    },
  },
};
</script>

<style scoped>
.errItem {
  border:solid 1px #dc3545 !important;
}
.error {
  font-size: 80%;
  color: #dc3545;
}
</style>

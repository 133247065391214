<template>
  <div class="col-sm-12">
    <div
      class="my-2 ml-1"
      v-for="(choice, choiceId) in radioData.choices" :key="choiceId">
      <!-- 上位の選択肢 -->
      <b-form-radio
        :disabled="!writable"
        :name="`mainCheck${choiceId}`"
        :value="choiceId"
        v-model="optionData(radioData.mainOptionId).field_answer_id"
        @change="resetChildValue(choiceId, radioData.mainOptionId)"
        stacked>
        <div class="choice-text">{{ choice.text }}</div>
      </b-form-radio>
        <!-- 中位テキスト追加時 -->
        <div
          v-if="choice.subTexts"
          class="choice-text sub-text">
          <div
            v-for="(data, subOptionId) in choice.subTexts" :key="subOptionId"
            class="mt-2">
            <b-input-group>
              <b-input-group-prepend
                class="mr-1 flex flexMiddle prefix-text-wrap">
                {{data.prefix}}
              </b-input-group-prepend>
            <b-form-input
              :class="[
                optionData(subOptionId).errMessages.length > 0 ? 'errItem' : '',
              ]"
              :disabled="!writable
                || !checkThisChoice(radioData.mainOptionId, choiceId)"
              v-model="optionData(subOptionId).field_answer_text"/>
            </b-input-group>
            <div class="err-wrap">
              <p class="error my-1"
                v-for="(msg, index) in optionData(subOptionId).errMessages"
                :key="index">
                {{msg}}
              </p>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'PartsApplicationTadio',
  props: {
    fieldId: { type: Number },
    notice: {},
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    writable: { type: Boolean, default: false },
    view_template: { type: Number, default: 1 },
    rules: { type: [Object, Array], fedault: () => {} },
  },
  data() {
    return {
    };
  },
  methods: {
    setSubChoice(option, rawCheckBoxData) {
      const checkBoxData = cloneDeep(rawCheckBoxData);
      // テキストのとき
      if (option.input_type === 1) {
        if (!checkBoxData.choices[option.parent_choice_id].subTexts) {
          checkBoxData.choices[option.parent_choice_id].subTexts = {};
        }
        checkBoxData.choices[option.parent_choice_id].subTexts[option.id] = {
          prefix: option.prefix,
          suffix: option.suffix,
        };
      }
      return checkBoxData;
    },
    resetChildValue(choiceId, optionId) {
      // 今、チェックを外したものを親とする子オプションをすべて検索する
      const formOptions = this.options.filter((option) => {
        const sameOption = option.parent_option_id === optionId;
        const sameChoice = option.parent_choice_id !== Number(choiceId);
        return sameOption && sameChoice;
      });

      if (formOptions.length === 0) {
        return;
      }

      formOptions.forEach((formOption) => {
        const param = {
          field_id: this.fieldId,
          option_id: formOption.id,
          answer_no: 1,
        };
        this.$store.commit('gasApplicationInput/resetValue', param);
      });
    },
  },
  computed: {
    optionData() {
      const self = this;
      return (optionId) => {
        const { inputs } = this.$store.state.gasApplicationInput;
        const optionData = inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          const sameOption = input.option_id === Number(optionId);
          const sameAnswerNo = input.answer_no === 1;
          return sameField && sameOption && sameAnswerNo;
        });
        return optionData;
      };
    },
    radioData() {
      let checkBoxData = {};
      if (this.options.length === 0) {
        return checkBoxData;
      }
      // 元データに影響を与えないようにコピー
      const rawOptionData = this.options.slice();
      // メインになるやつ
      let mainId = null;
      rawOptionData.forEach((option) => {
        if (!option.parent_option_id) {
          mainId = option.id;
          checkBoxData = {
            mainOptionId: mainId,
          };
          const choices = {};
          Object.keys(option.choice_texts).forEach((choiceId) => {
            choices[choiceId] = {};
            choices[choiceId].text = option.choice_texts[choiceId];
          });
          checkBoxData.choices = choices;
          // rawOptionData.splice(index, 1);
        }
      });
      rawOptionData.forEach((option) => {
        if (option.parent_option_id === checkBoxData.mainOptionId) {
          checkBoxData = this.setSubChoice(option, checkBoxData);
          // rawOptionData.splice(index, 1);
        }
      });
      return checkBoxData;
    },
    checkThisChoice() {
      return (optionId, choiceId) => {
        const fieldAnswerId = this.optionData(optionId).field_answer_id;
        return String(fieldAnswerId) === choiceId;
      };
    },
  },
};
</script>

<style>

.custom-radio .custom-control-label::before {
  border-radius: 50% !important;
  width: 25px;
  height: 25px;
}

.custom-radio .custom-control-label::after {
  width: 25px;
  height: 25px;
}

</style>

<style scoped>
.errItem {
  border:solid 1px #dc3545 !important;
}
.error {
  font-size: 80%;
  color: #dc3545;
}

.template_1 {
  width: 220px;
  max-width: 220px;
}

.template_2 {
  width: 400px;
  max-width: 400px;
}

.optionLabel.label_1 {
  width: 55px;
}

.optionLabel.label_2 {
  width: 160px;
}

.error_1 {
  margin-left: 60px;
}

.error_2 {
  margin-left: 165px;
}

.choice-text {
  margin-left: 10px;
  margin-top: 4px;
}

.sub-text {
  width: 600px;
}

.err-wrap {
  margin-left: 100px;
}

.prefix-text-wrap {
  width: 100px;
}
</style>

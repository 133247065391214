<template>
  <div>
    <b-row class="mt-2 mx-4 mb-2">
      <div class="label pl-0 mb-0 pr-1 mr-3">
        <div class="badgeWrap">
          <div v-if="is_required" class="mr-2 badge badge-required">必須</div>
        </div>
        <div class="labelWrap mb-0">
          <label class="formLabel mb-0 bold">{{ fieldLabel }}</label>
        </div>
      </div>
      <div class="mainWidth">

        <b-row
          v-if="messages.length > 0"
          class="ml-1 mt-2">
          <p
            class="mb-0 form-file-line"
            v-for="(data, index) in messages" :key="index">
            <span v-if="data.type === 1"
              :class="[
                `message_${data.view_template}`,
              ]"
              v-html="data.message"></span>
            <span
              v-if="data.type === 3"
              :class="[
                `message_${data.view_template}`,
              ]">
              ダウンロード：
              <b-link
                @click="downloadOpenFile(assetId(index), data.message)"
                class="mb-0">
                {{data.message}}
              </b-link>
            </span>
          </p>
        </b-row>
        <div v-if="type !== 100">
          <component
            :is="typeMap[type]"
            :fieldId='fieldId'
            :is_variable="is_variable"
            :input_number="input_number"
            :min_input_number="min_input_number"
            :max_input_number="max_input_number"
            :options.sync="options"
            :notice="notice"
            :writable="writable"
            :view_template="view_template"
            :rules="rules"
            :messages="messages"
            :params="params"
            :downloadFormFile="downloadFormFile"
            @kickSetText="setText($event)"
            @kickSetSelect="setSelect($event)"
            @kickSetFile="setFile($event)"
            />
        </div>
        <div v-if="type === 100">
          <component
            :is="specialConponentNameList[view_template]"
            :fieldId='fieldId'
            :options.sync="options"
            :notice="notice"
            :writable="writable"
            :messages="messages"
            :params="params"
            @kickSetText="setText($event)"
            @kickSetSelect="setSelect($event)"
            @kickSetFile="setFile($event)"
            />
        </div>

        <div
          v-if="messages.length > 0"
          class="ml-1 mt-1">
          <p
            v-for="(data, index) in messages" :key="index"
            class="mb-0">
            <!-- <b-col class="px-0" v-if="data.type === 2">
              <p class="mb-0 f-13">{{data.message}}</p>
            </b-col>

            <b-col class="px-0" v-if="data.type === 4">
              <p class="mb-0 f-13">{{data.message}}</p>
            </b-col> -->
            <span class="f-13" v-if="data.type === 2">{{data.message}}</span>
            <span class="f-13 ml-3" v-if="data.type === 4">
              ダウンロード：{{data.report_type_asset_id}}
              <b-link
                @click="downloadOpenFile(assetId(index), data.message)"
                class="mb-0">
                {{data.message}}
              </b-link>
            </span>
          </p>
        </div>

      </div>
    </b-row>
  </div>
</template>
<script>
import Input from '@/components/parts/application/Input.vue';
import Textarea from '@/components/parts/application/Textarea.vue';
import Check from '@/components/parts/application/Check.vue';
import Radio from '@/components/parts/application/Radio.vue';
import File from '@/components/parts/application/File.vue';
import Table from '@/components/parts/application/Table.vue';
import FinancingPlan from '@/components/parts/application/special/FinancingPlan_1.vue';
import kihuMoney from '@/components/parts/application/special/KihuMoney_2.vue';
import CONST_STATUS from '@/constants/specialField';

export default {
  name: 'PartsApplicationText',
  components: {
    'v-app-input': Input,
    'v-app-textarea': Textarea,
    'v-app-check': Check,
    'v-app-radio': Radio,
    'v-app-file': File,
    'v-app-table': Table,
    'v-app-financing-plan': FinancingPlan,
    'v-app-kihu-money': kihuMoney,
  },
  props: {
    fieldId: { type: Number },
    fieldLabel: { type: String },
    description: { type: String, default: '' },
    notice: { type: String, default: '' },
    type: { type: Number },
    is_required: { type: [Boolean, Number], default: false },
    // 記入項目マスタ
    label: { type: String, default: 'オプションラベル' },
    is_variable: { type: [Boolean, Number], default: true },
    input_number: { type: Number, default: 1 },
    min_input_number: { type: Number, default: 1 },
    max_input_number: { type: Number, default: 1 },
    view_template: { type: Number, default: 1 },
    writable: { type: Boolean, default: false },
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    assets: { type: [Object, Array], default: () => {} },
    rules: { type: [Object, Array], fedault: () => {} },
    messages: { type: [Object, Array], default: () => [] },
    params: { type: [Object, Array], default: () => [] },
    downloadFormFile: { type: Function },
    downloadOpenFile: { type: Function },
  },
  data() {
    return {
      typeMap: {
        1: 'v-app-input',
        2: 'v-app-textarea',
        3: 'v-app-check',
        4: 'v-app-radio',
        6: 'v-app-file',
        7: 'v-app-table',
      },
    };
  },
  methods: {
    setText(param) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('gasApplicationInput/setText', param);
    },
    setSelect(param) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('gasApplicationInput/setSelect', param);
    },
    setFile(param) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('gasApplicationInput/setFile', param);
    },
  },
  computed: {
    specialConponentNameList() {
      return CONST_STATUS.COMPONENT_NAMES;
    },
    apptypeId() {
      return this.$store.state.gasApplicationForm.application_type_id;
    },
    assetId() {
      return (index) => {
        if (this.messages[index].report_type_asset_id) {
          return this.messages[index].report_type_asset_id;
        }
        return this.messages[index].application_type_asset_id;
      };
    },
  },
};
</script>

<style scoped>
  .titleWrap {
    padding: 8px 5px 8px 10px;
    border: 1px solid #ddd;
  }
  .head {
    background-color: #122889;
    width: 5px;
    height: 22px;
  }
  .title {
    font-size: 126%;
    line-height: 1.2em;
  }

  .formLabel {
    white-space: pre-line;
    position: absolute;
    top: 23px;
  }

  .badgeWrap {
    width: 44px;
    height: 50px;
    display: inline-block;
  }

  .badge-required {
    position: absolute;
    top: 25px;
  }

  .labelWrap {
    display: inline-block;
  }

  .label {
    width: 230px;
    max-width: 230px;
  }

  .mainWidth {
    width: 750px;
    max-width: 750px;
  }

  .fileNotice {
    font-size: 13px;
  }

  .message_1 {
    font-size: 13px;
  }

  .form-file-line {
    width: 100%;
  }

</style>

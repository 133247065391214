
const financingPlan = 1; // 資金計画
const kihuMoney = 2; // 申請金額（寄付）

const COMPONENT_NAMES = {
  [financingPlan]: 'v-app-financing-plan',
  [kihuMoney]: 'v-app-kihu-money',
};

export default {
  COMPONENT_NAMES,
};
